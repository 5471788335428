.login_bg {
  min-height:100%;
  min-width: 100%;
  background:url('/bgfina.jpg');
  background-position: 0px 0;
  background-size:cover;
  box-shadow:inset 0 0 0 2000px #008ABE88;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.login_card {
  flex: 1;
  max-width: 350px;
  background-color: white;
  border-radius: 8px;
  border-color: #008ABE;
  overflow: hidden;
}

.login_card_body {
  display: flex;
  flex: 1;
  padding: 20px 20px 20px 20px;
  justify-content: center;
  flex-direction: column;
}

.login_card_logo_container {
  background-color: #008ABE;
  min-height: 120px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login_card_logo_container > img {
  max-height: 100px;
}

.login_input {
  flex: 1;
  font-family: 'DIN';
  font-size: 14px;
  min-height: 32px;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  border-color: #00000055;
  padding-left: 8px;
  padding-right: 8px;
  outline: none;
}

.login_input_sublabel {
  font-size: 12px;
  margin-top: 2px;
  margin-left: 8px;
  margin-bottom: 12px;
  color: red;
  opacity: 0.0;
  transition: 0.2s;
}

.visible {
  opacity: 1.0;
}

.login_input:focus {
  border-color: #008ABE;
}

.login_button {
  font-family: 'DIN';
  min-height: 32px;
  height: 40px;
  border-radius: 4px;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 20px;
  border-width: 0px;
  color: white;
  background-color: #008ABE;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.15s;
  margin-top: 8px;
}

.login_button:active {
  opacity: 0.7;
}

.login_button:disabled {
  opacity: 0.5;
}

.disabled {
  opacity: 0.5;
}